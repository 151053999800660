import { WaitlistScreen } from "./features/Waitlist/WaitlistScreen";
import { AppThemeProvider } from "../../theme/AppThemeProvider";

import { createMarketingAppTheme } from "../../theme/createMarketingAppTheme";
import { QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { queryClient } from "../../services/queryClient";

export function MarketingApp() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppThemeProvider createAppThemeFn={createMarketingAppTheme}>
        <SnackbarProvider>
          <WaitlistScreen />
        </SnackbarProvider>
      </AppThemeProvider>
    </QueryClientProvider>
  );
}
