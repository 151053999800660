import * as yup from "yup";
import { InferType } from "yup";
import { getApiHeaders } from "../../api";
import axios from "axios";
import { gettext } from "../../services/i18n";

export const waitlistFormSchema = yup.object().shape({
  email: yup
    .string()
    .email(gettext(gettext("Enter a valid email address")))
    .max(100)
    .required(gettext("Email address is required")),
  firstName: yup.string().max(100).required(gettext("First name is required")),
  lastName: yup.string().max(100).required(gettext("Last name is required")),
  companyName: yup
    .string()
    .max(100)
    .required(gettext("Company name is required")),
  ticketingSystem: yup
    .string()
    .max(100)
    .required(gettext("Ticketing system is required")),
});

export type WaitListFormValues = InferType<typeof waitlistFormSchema>;

type SubmitWaitlistFormData = {
  email: string;
  first_name: string;
  last_name: string;
  company_name: string;
  ticketing_system: string;
};

export type SubmitWaitlistFormErrorResponse = Record<
  keyof SubmitWaitlistFormData,
  string[]
>;

export function submitWaitlistForm(values: WaitListFormValues) {
  const headers: Record<string, string> = { ...getApiHeaders() };
  headers["Content-Type"] = "application/x-www-form-urlencoded";

  const formData: SubmitWaitlistFormData = {
    email: values.email,
    ticketing_system: values.ticketingSystem,
    company_name: values.companyName,
    first_name: values.firstName,
    last_name: values.lastName,
  };

  return axios.post<SubmitWaitlistFormErrorResponse>("/waitlist/", formData, {
    headers,
  });
}
