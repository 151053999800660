import { Button, Stack, Typography } from "@mui/material";
import { gettext } from "../../../../services/i18n";
import { Grid } from "../../../../components/common/layout/Grid";

export function ReserveNowOrSuccessContent({
  onReserveNowClick,
  isSuccess,
}: {
  onReserveNowClick: () => void;
  isSuccess: boolean;
}) {
  if (isSuccess) {
    return (
      <Stack spacing={2}>
        <Typography variant="h4">{gettext("You're on the list.")}</Typography>
        <Typography variant="body1">
          {gettext(
            "You have been successfully added to the waiting list. We'll be in touch!",
          )}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h5">
        {gettext("Reserve your spot in the waiting list")}
      </Typography>
      <Typography variant="body1">
        {gettext(
          `Quality takes time. To ensure customer success, we're onboarding property management companies at a limited rate. Sign up here and we'll reach out to you when we have capacity to discuss how Captured Knowledge can make your team more efficient.`,
        )}
      </Typography>
      <Grid textAlign="center">
        <Button variant="contained" onClick={onReserveNowClick}>
          {gettext(`Reserve now`)}
        </Button>
      </Grid>
    </Stack>
  );
}
