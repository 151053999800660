import { createAppTheme, CreateAppThemeParams } from "./createAppTheme";
import { createTypography } from "./theme/create-typography";

export const createMarketingAppTheme: typeof createAppTheme = (
  params: CreateAppThemeParams,
) => {
  return createAppTheme({
    ...params,
    themeOptions: {
      typography: createTypography({
        fontFamily: "Montserrat, sans-serif",
      }),
    },
  });
};
